<template>
<ul>
  <li class="logo">
    <img src="@/assets/images/logosm.png" alt="logo" height="36px">
    <span>博喻服务</span>
  </li>
  <li class="nav-item" :class="{active:$route.name=='home'}" v-if="user.authority.length>0">
    <router-link to="/home">首页</router-link>
  </li>
  <!-- 薪资管理下拉选择 -->
  <li class="nav-item text-center" :class="{active:isSalary}" v-if="isBoss">
    <div>
      薪资管理
      <i class="el-icon-caret-bottom" :style="{color:isSalary?'#ffffff':'#909399'}"></i>
    </div>
    <div class="submenu">
      <router-link to="/last_salary" :class="{active:$route.name=='last_salary'}">上月薪资</router-link>
      <router-link to="/history_salary" :class="{active:$route.name=='history_salary'}">历史薪资</router-link>
      <router-link to="/price" :class="{active:$route.name=='price'}">课时费单价</router-link>
    </div>
  </li>
  <li class="nav-item" :class="{active:$route.name=='teaclass'}">
    <router-link to="/teaclass">{{user.authority.length===0?'我的课表':'排课表'}}</router-link>
  </li>
  <li class="nav-item" :class="{active:$route.name=='tealist'}" v-if="user.subject.length>0&&user.authority.indexOf('2')==-1">
    <router-link :to="'/tealist/'+indexTea">课时信息</router-link>
  </li>
  <li
    class="nav-item"
    :class="{active:$route.name==item.name}"
    v-for="item in gridData" :key="item.right"
  >
    <router-link :to="item.to">{{item.text}}</router-link>
  </li>
  <!-- 数据管理下拉选择 -->
  <li class="nav-item text-center" :class="{active:isShuju}" v-if="gridData1.length>0">
    <div>
      数据管理
      <i class="el-icon-caret-bottom" :style="{color:isShuju?'#ffffff':'#909399'}"></i>
    </div>
    <div class="submenu">
      <router-link
        v-for="item in gridData1" :key="item.right"
        :to="item.to"
        :class="{active:$route.name==item.name}"
      >{{item.text}}</router-link>
    </div>
  </li>
  <!-- 用户下拉选择 -->
  <li class="nav-item text-center user" :class="{active:isUser}">
    <div class="user-title">
      <el-avatar :src="user.avatar" v-if="user.avatar" alt="头像"></el-avatar>
      <el-avatar icon="el-icon-user-solid" v-else alt="头像"></el-avatar>
      <span>{{user.name}}</span>
      <i class="el-icon-caret-bottom" :style="{color:isUser?'#ffffff':'#909399'}"></i>
    </div>
    <div class="submenu">
      <router-link to="/updatePwd" :class="{active:$route.name=='updatePwd'}">修改密码</router-link>
      <router-link
        v-for="item in gridData2" :key="item.right"
        :to="item.to"
        :class="{active:$route.name==item.name}"
      >{{item.text}}</router-link>
      <a href="javascript:;" @click="logout">退出登录</a>
    </div>
  </li>
  <message-box v-if="!isRoot"></message-box>
</ul>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import MessageBox from "@/components/PC/MessageBox.vue";
export default {
  components:{MessageBox},
  name:"MyHeader",
  data(){
    return {
      gridData: [
        { right: "5", name: "stuclass", text: "发课表", to: "/stuclass/0" },
        { right: "2", name: "tealist", text: "教师列表", to: "/tealist/0" },
        { right: "1", name: "stulist", text: "学生列表", to: "/stulist/0" },
        { right: "3", name: "banlist", text: "班课列表", to: "/banlist/0" },
      ],
      // 数据管理下拉选择菜单
      gridData1: [
        { right: "J", name: "jiabanwanfu", text: "加班晚辅", to: "/jiabanwanfu"},
        { right: "9", name: "heiduikeshi", text: "课时核对", to: "/heiduikeshi" },
        { right: "Q", name: "echarts", text: "数据图表", to: "/echarts" },
      ],
      // 用户下拉选择菜单
      gridData2: [
        { right: "6", name: "addStu", text: "添加学生", to: "/addStu"},
        { right: "7", name: "addTea", text: "添加教师", to: "/addTea" },
        { right: "8", name: "addBan", text: "添加班课", to: "/addBan" },
      ],
    }
  },
  computed: {
    ...mapState(["user","teaList"]),
    ...mapGetters(["isBoss","isRoot"]),
    // 当前路径是否在用户管理中
    isUser(){
      return this.gridData2.some(item=>item.name===this.$route.name);
    },
    // 当前路径是否在数据管理中
    isShuju(){
      return this.gridData1.some(item=>item.name===this.$route.name);
    },
    // 当前路径是否在薪资管理中
    isSalary(){
      return this.$route.name=='last_salary'||this.$route.name=='history_salary'||this.$route.name=='price'
    },
    // 跳转到教师列表的index
    indexTea(){
      return this.teaList.findIndex(tea=>tea.tid===this.user.id)
    }
  },
  methods: {
    ...mapMutations(["logout"]),
  },
  created() {
    this.gridData = this.gridData.filter((item) => this.user.authority.indexOf(item.right) >= 0);
    this.gridData1 = this.gridData1.filter((item) => this.user.authority.indexOf(item.right) >= 0);
    this.gridData2 = this.gridData2.filter((item) => this.user.authority.indexOf(item.right) >= 0);
  },
};
</script>

<style scoped>
/* 整体 */
ul{
  list-style: none;
  display: flex;
  margin: 0 auto;
  background-color: #F5F7FA;
  position: sticky;
  top: 0;
  z-index: 10;
}
ul>li{
  height: 56px;
}
/* logo */
ul>.logo{
  display: flex;
  align-items: center;
  font:22px 楷体;
  cursor: default;
}
ul>.logo>span{
  margin: 0 12px;
  line-height: 56px;
}
ul>.nav-item>a,
ul>.nav-item>div{
  display: block;
  padding: 0 16px;
  color: #303133;
  line-height: 56px;
  cursor: pointer;
}
ul>.nav-item:hover,
.nav-item>.submenu>a:hover{
  background-color:  #ffc4c1;
}
ul>.nav-item.active{
  background-color: #E1251B;
}
.nav-item>.submenu>a.active{
  color: #E1251B;
}
ul>.nav-item.active>a,
ul>.nav-item.active>div{
  color: #FFFFFF;
}

/* 下列选择 */
.nav-item>.submenu{
  opacity: 0;
  height: 0;
  padding: 0;
  border: 0;
  border-radius: 6px;
  background-color: #FFFFFF;
  z-index: 200;
  overflow: hidden;
}
.nav-item:hover .el-icon-caret-bottom{
  transform: rotate(180deg);
  transition: all 800ms ease;
}
.nav-item:hover>.submenu{
  transition: all 800ms ease;
  opacity: 1;
  height: auto;
  border: 1px solid #909390;
  padding: 3px;
}
.nav-item>.submenu>a{
  display: block;
  padding: 10px 0;
  color: #303133;
  line-height: 18px;
}

/* 用户 */
.nav-item.user{
  position: absolute;
  right: 8px;
}
.user>.user-title{
  display: flex;
  align-items: center ;
}
.user>.user-title>span{
  margin-right: 6px;
}
</style>
