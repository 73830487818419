<template>
  <el-container :style="{ height: winHeight + 'px' }">
    <el-header>
      <h1>博喻后台管理系统</h1>
    </el-header>
    <el-main>
      <!-- 登录表单 -->
      <div class="form">
        <h3>教师登录</h3>
        <el-form
          ref="ruleFormRef"
          label-width="80px"
          :model="ruleForm"
          :rules="rules"
          status-icon
        >
          <el-form-item label="用户名" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入用户名" />
          </el-form-item>
          <el-form-item label="用户密码" prop="pwd">
            <el-input
              v-model="ruleForm.pwd"
              type="password"
              placeholder="请输入密码"
              show-password
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleFormRef')"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-main>
    <el-footer>
      <p>君子知至学之难易 而知其美恶 然后能博喻 能博喻 然后能为师</p>
      <span
        >Copyright © 自然选择号 
        <a href="https://beian.miit.gov.cn/" class="text-primary">浙ICP备2022004927号-2</a></span
      >
    </el-footer>
  </el-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        name: "",//登录名
        pwd: "",//登录密码
      },
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState(["winHeight","user"]),
  },
  methods: {
    ...mapActions(["login"]),
    // 提交表单  登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 校验成功后提交发送请求
          this.login({name:this.ruleForm.name,pwd:this.ruleForm.pwd}).then(res=>{
            if(res){
              this.$notify.error({
                title: "登录失败",
                message: res,
                offset: 56
              });
            }else{
              if(this.user.authority.length>0){
                this.$router.push("/home"); // 跳转到主页
              }else{
                this.$router.push("/teaclass"); // 跳转到课表页
              }
              this.$notify({
                title: "登录成功",
                message: "欢迎" + this.user.name,
                type: "success",
                offset: 56,
                duration: 600
              });
            }
          })
        } else {
          // 校验失败
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.form>h3{
  margin: 1em 0;
}
.el-header > img {
  float: left;
}
.el-header > h1 {
  width: 50%;
  text-align: center;
  margin: 0 auto;
  padding-top: 2rem;
  font-family: "STXingkai", "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei";
  font-size: 36px;
  font-weight: 500;
}
.el-main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-main .form {
  width: 320px;
  margin: 0 auto;
}
.el-main .form h3 {
  text-align: center;
}
.el-main .form .el-button{
  width: 100%;
}

.el-footer {
  text-align: center;
}
.el-footer > p {
  margin: 12px 0 2px 0;
  font: 1.2em 楷体;
}
.el-footer > span {
  font-size: 0.6em;
}
</style>
