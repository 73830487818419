<template>
  <div class="login" :style="{ height: winHeight + 'px' }">
    <!-- 头部 -->
    <div class="title">
      <img src="../../assets/images/logo.png" alt="logo" width="30%" />
      <h2>博喻管理系统</h2>
    </div>
    <!-- 登录表单 -->
    <div class="form">
      <h3>教师登录</h3>
      <van-form @submit="onSubmit">
        <van-cell-group>
          <van-field
            v-model.trim="name"
            clearable
            name="用户名"
            label="用户名"
            placeholder="请输入用户名"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model.trim="password"
            clearable
            type="password"
            name="密码"
            label="密码"
            autocomplete="off"
            placeholder="请输入用户密码"
            :rules="[{ required: true },{pattern:pwdPattern,message:'请输入6~8位字母或数字'}]"
          />
        </van-cell-group>
        <div style="margin: 20px">
          <van-button round block type="primary" native-type="submit">
            登&nbsp;&nbsp;&nbsp;&nbsp;录
          </van-button>
        </div>
      </van-form>
    </div>
    <!-- 底部 -->
    <div class="footer">@ziranxuanzehao</div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Dialog } from 'vant';
export default {
  computed: {
    ...mapState(["winHeight"]),
  },
  data(){
    return {
      pwdPattern: /^[a-zA-Z0-9]{6,8}$/,
      name:'',
      password:''
    }
  },
  methods:{
    ...mapActions(['login']),
    onSubmit(){
      this.login({name:this.name,pwd:this.password}).then(res=>{
        if(res){
          Dialog.alert({
            message: res,
            theme: 'round-button',
          })
        }else{ // 登录成功
          this.$router.push("/home"); // 跳转到主页
        }
      })
    }
  }
};
</script>

<style scoped>
.login {
  position: relative;
  width: 100%;
  text-align: center;
  background-color: #F5F7FA;
}
.login > .title > img {
  float: left;
}
.login h2 {
  width: 80%;
  margin: 0 auto;
  padding-top: 2.5em;
}
.login > .form {
  width: 90%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login > .form > h3{
  margin-bottom: 48px;
}
.login > .footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 12px;
  color: #909399;
}
</style>
